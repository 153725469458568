
  h1 {
    text-align: center;
    margin: 2rem 0 4rem 0;
  }
  
  .accordion {
    max-width: 1100px;
    margin: 2rem auto;
  }
  
  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: #dddddd;
  }
  
  .accordion-title:hover {
    background-color: #00a19a !important;
    color: #Fff !important;
  }
  
  .accordion-title,
  .accordion-content {
    padding: 1rem;
  }
  
  .accordion-content {
    background: #ffffff;
    color: #333333;
    padding: 1em 2.2em;
    border-top: 0;
    overflow: auto;
    font-size: 16px;
  }
  
  @media screen and (max-width: 700px) {
    body {
      font-size: 18px;
    }
  
    .accordion {
      width: 90%;
    }
  }

  .group-title {
    color: #00a19a;
    margin: 25px 0;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1.5;
    padding-bottom: 0.25em;
    border-bottom: 1px solid #ddd;
    width: 100%;
    font-weight: bold;
}
.accordion-name{
  color: #00a19a;
  margin: 25px 0;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.5;
  padding-bottom: 0.25em;
  border-bottom: 1px solid #ddd;
  width: 100%;
  font-weight: bold;

}
.accordion-item{
    display: block;
    cursor: pointer;
    position: relative;
    margin: 10px 0 0 0;
    font-size: 15px;
    font-weight: bold;
    border: 0px;
    font-weight: normal;
    color: #000 ;
    font-size: 20px ;
}

.faq-main-box .col-sm-2 {
    justify-content: center;
    display: flex;
}
.faq_imgbox {
    border: 2px solid #00a19a;
    padding: 10px;
    width: 130px;
    height: 130px;
    cursor: pointer;
}
.faq_imgboxe {
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
}
.faq_imgbox img {
    height: 50px;
    width: auto;
    margin: auto;
    display: table;
}
.faq_imgbox .faq_para {
    height: 35px;
    position: relative;
    margin-top: 5px;
}
.faq_imgbox p {
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0px;
    color: #017b76;
    font-weight: 600;
    transform: translate(0%, -50%);
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    font-size: 14px;
    line-height: normal;
}

.faq-acod-title {
    display: table;
    width: 100%;
    max-width: 1100px;
    margin: auto;
    margin-top: 50px;
}

.accordion h1 {
    float: left;
    text-align: left;
    color: #282828;
    display: table;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 20px;
}

.faq-tbtn {
    float: right;
    margin-top: 15px;
}

.faq-tbtn button {
    padding: 5px 10px;
    background-color: #00a19a;
    color: #fff ;
    margin-left: 10px;
    border-radius: 10px;
    text-decoration: none;
    font-size: 16px;
    border-color: transparent;
}

.faqtopbanner {
    height: 300px;
    background: #00a19a;
    position: relative;
}
.faq_mainbox {
    transform: translate(0%, -50%);
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.faqtopbanner h1 {
    color: #fff;
    width: auto;
    text-align: center;
    text-transform: uppercase;
    font-size: 34px;
}
.faq_mainbox .faq_searchbox {
    margin: auto;
    display: table;
    max-width: 700px ;
    display: block ;
}
.faqtopbanner .faq_mainbox input {
    border-radius: 15px;
    height: 45px;
}
.block-search .action.search {
    right: 15px;
    top: 5px;
}
.faq_mainbox .faq_searchbox .btn {
    top: 5px;
    width: 40px;
    background-color: #00a19a;
    bottom: 5px;
    height: auto;
    color: #fff;
}
.Expan{
  display: block !important;
  background-color: red;
}